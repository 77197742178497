import LayoutComponent from "../../../Components/Onboarding/LayoutComponent";
import SignUpComponent from "../../../Components/Onboarding/SignUpComponent";
import staticData from "../../../Components/staticData";
import styles from "./SignUp.module.css";

const signInPage = staticData.signInUpPage;

const SignUp = () => {
  return (
    <div className={styles.Wrapper}>
      <LayoutComponent
        renderComp={<SignUpComponent />}
        headerText={signInPage.signUp.subTitle[0]}
        headerLinkText={signInPage.signUp.subTitle[1]}
        headerLink={"/signin"}
      />
    </div>
  );
};

export default SignUp;
