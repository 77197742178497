import {
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  HANDLE_USER_TOKENS,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} from "./types";

export function setSignup() {
  return {
    type: SIGN_UP,
  };
}

export function setSignupSuccess(message, email, contact) {
  return {
    type: SIGN_UP_SUCCESS,
    payload: message,
    email,
    contact,
  };
}

export function setSignupError(error) {
  return {
    type: SIGN_UP_ERROR,
    payload: error,
  };
}

export function setVerifyOTP() {
  return {
    type: VERIFY_OTP,
  };
}

export function setVerifyOTPSuccess(message, newUser) {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: message,
  };
}

export function setVerifyOTPError(error) {
  return {
    type: VERIFY_OTP_ERROR,
    payload: error,
  };
}

export function setSignin() {
  return {
    type: SIGN_IN,
  };
}

export function setSigninSuccess(message, email) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: message,
    email,
  };
}

export function setSigninError(error) {
  return {
    type: SIGN_IN_ERROR,
    payload: error,
  };
}

export const setHandleUserTokens = (accessToken) => {
  return {
    type: HANDLE_USER_TOKENS,
    accessToken: accessToken,
  };
};

export function setResendOTP() {
  return {
    type: RESEND_OTP,
  };
}

export function setResendOTPSuccess(message) {
  return {
    type: RESEND_OTP_SUCCESS,
    payload: message,
  };
}

export function setResendOTPError(error) {
  return {
    type: RESEND_OTP_ERROR,
    payload: error,
  };
}

export function setRefreshToken() {
  return {
    type: REFRESH_TOKEN,
  };
}

export function setRefreshTokenSuccess(message) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: message,
  };
}

export function setRefreshTokenError(error) {
  return {
    type: REFRESH_TOKEN_ERROR,
    payload: error,
  };
}

export function setLogout() {
  return {
    type: LOGOUT,
  };
}

export function setLogoutSuccess(message) {
  return {
    type: LOGOUT_SUCCESS,
    payload: message,
  };
}

export function setLogoutError(error) {
  return {
    type: LOGOUT_ERROR,
    payload: error,
  };
}
