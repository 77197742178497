import Button from "../../Button/Button";
import PlugSocketIcon from "../../../Assets/General/PlugSocketIcon.svg";
import KeyIcon from "../../../Assets/General/KeyIcon.svg";
import CopyIcon from "../../../Assets/General/CopyIcon.svg";
import staticData from "../../staticData";
import styles from "./ApiKeyPopup.module.css";
import { useSelector } from "react-redux";
import usePopup from "../../../Hooks/usePopup";
import { useEffect } from "react";
import useAccount from "../../../Hooks/useAccount";

const content = staticData.ApiKeyPage.ApiKeyPopup;

const ApiKeyPopup = () => {
  const {
    account: accountData,
    apiKey,
    apiSecretKey,
  } = useSelector((state) => state.account);
  const { handlePopupCenterOpen } = usePopup();
  const { handleRefreshAccountKeys } = useAccount();
  const handleClose = () => {
    handlePopupCenterOpen(false);
  };

  return accountData ? (
    <div className={styles.Wrapper}>
      <h1 className={styles.Heading}>{content.heading}</h1>
      <div className={styles.KeyWrapper}>
        <span className={styles.SecondaryText}>
          {" "}
          <img src={PlugSocketIcon} alt="" /> {content.apiKeyText}
        </span>
        <span className={styles.PrimaryText}>{apiKey}</span>
        <Button
          className={styles.CopyBtn}
          text={content.copyBtnText}
          src={CopyIcon}
        />
      </div>
      <div className={styles.KeyWrapper}>
        <span className={styles.SecondaryText}>
          {" "}
          <img src={KeyIcon} alt="" /> {content.secretKeyText}
        </span>
        <p className={styles.PrimaryText}>{apiSecretKey}</p>
        <Button
          className={styles.CopyBtn}
          text={content.copyBtnText}
          src={CopyIcon}
        />
      </div>
      <div className={styles.NoteWrapper}>
        <span className={`${styles.PrimaryText} ${styles.Note}`}>
          {content.note}
        </span>
      </div>
      <div className={styles.ButtonsWrapper}>
        <Button text={content.downloadBtnText} btnType="primary" />
        <Button
          text={content.finishBtnText}
          className={styles.FinishBtn}
          onClick={handleClose}
        />
      </div>
    </div>
  ) : null;
};

export default ApiKeyPopup;
