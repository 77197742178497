import { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Services/auth/logout";
import { refreshToken } from "../Services/auth/refreshToken";
import { axiosPrivate } from "../Services/axios";
import { setHandleUserTokens } from "../Redux/auth/actions";
import useAuth from "./useAuth";
import notify from "../Utils/notifyToast";

function useAxiosPrivate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessToken = useSelector((state) => state.auth.accessToken);

  const { handleRefreshToken } = useAuth();

  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (
          error?.response?.data?.errors?.[0]?.message === "jwt expired" &&
          !prevRequest.sent
        ) {
          prevRequest.sent = true;
          let refreshTokenData;
          try {
            const { data } = await refreshToken();
            refreshTokenData = data;
          } catch (error) {
            if (
              error?.response?.data?.errors?.[0]?.message === "jwt expired" &&
              error?.response?.data?.errors?.[0].name === "TokenExpiredError"
            ) {
              navigate(0);
              toast.dismiss();
              return await logout();
            }
          }
          const newAccessToken = refreshTokenData.accessToken;
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          dispatch(setHandleUserTokens(newAccessToken));
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, [handleRefreshToken, accessToken, dispatch]);

  return axiosPrivate;
}

export default useAxiosPrivate;
