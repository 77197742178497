import { useLocation, useNavigate } from "react-router";
import Button from "../Button/Button";
import staticData from "../staticData";
import styles from "./SideMenu.module.css";

const content = staticData.SideMenu;

const SideMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(`/${route}`);
  };

  return (
    <div className={styles.Wrapper}>
      {content.options.map((option) => (
        <Button
          isReverse={true}
          key={option.btnText}
          text={option.btnText}
          src={option.image}
          className={`${
            pathname.includes(option.key) && option.key
              ? styles.ActiveMenu
              : styles.MenuBtn
          }`}
          imgClassName={styles.MenuIcon}
          onClick={() => handleClick(option.route)}
        />
      ))}
    </div>
  );
};

export default SideMenu;
