import Button from "../../Button/Button";
import staticData from "../../staticData";
import RefreshArrow from "../../../Assets/General/RefreshArrow.svg";
import DisableIcon from "../../../Assets/General/DisableIcon.svg";
import TrashIcon from "../../../Assets/General/TrashIcon.svg";
import styles from "./ApiKey.module.css";
import usePopup from "../../../Hooks/usePopup";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useAccount from "../../../Hooks/useAccount";
import RefreshKeyWarningPopup from "../RefreshKeyWarningPopup/RefreshKeyWarningPopup";

const content = staticData.ApiKeyPage.ApiKey;

const ApiKey = () => {
  const { handlePopupCenterOpen, handlePopupCenterComponentRender } =
    usePopup();
  const { account: accountData, loading } = useSelector(
    (state) => state.account
  );
  const { accessToken } = useSelector((state) => state.auth);
  const { handleAccount, handleAccountKeysToggle } = useAccount();

  const handleRefreshPopup = () => {
    handlePopupCenterOpen(true);
    handlePopupCenterComponentRender(<RefreshKeyWarningPopup />);
  };

  const handleDisableApiKey = () => {
    handleAccountKeysToggle(accountData.enabled ? false : true);
  };
  const handleDeleteApiKey = () => {};

  useEffect(() => {
    if (accessToken) {
      handleAccount();
    }
  }, [accessToken]);

  return accountData ? (
    <div className={styles.Wrapper}>
      <h1>{content.heading}</h1>
      <table className={styles.ApiKeyTable}>
        <tbody>
          <tr className={styles.InfoText}>
            <td>Partner Name</td>
            <td> Api Key </td>
          </tr>
          <tr>
            <td className={styles.InfoText}>
              {accountData?.organization.name}
            </td>
            <td className={styles.ApiKeyText}>{accountData?.apiKey}</td>
            <td>
              <Button
                text={"Delete"}
                src={TrashIcon}
                isReverse
                className={styles.DeleteBtn}
                onClick={handleDeleteApiKey}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Button
        isReverse={true}
        src={RefreshArrow}
        text={content.refreshBtnText}
        btnType={"primary"}
        onClick={handleRefreshPopup}
      />
      <Button
        src={accountData.enable ? DisableIcon : null}
        isReverse
        text={
          accountData.enabled ? content.disableBtnText : content.enableBtnText
        }
        className={styles.DisableBtn}
        onClick={handleDisableApiKey}
        isLoading={loading}
        isDisabled={loading}
      />
    </div>
  ) : null;
};

export default ApiKey;
