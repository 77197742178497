import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useLocation } from "react-router";
import styles from "./PhoneInputComponent.module.css";

const PhoneInputComponent = ({
  labelText,
  setMobileNum,
  disabled = false,
  value,
}) => {
  const [phoneInputClass, setPhoneInputClass] = useState({
    inputClass: styles.PhoneInput,
    buttonClass: styles.PhoneInputButton,
    dropdownClass: styles.PhoneInputDropdown,
    searchClass: styles.PhoneInputSearch,
  });

  const { pathname } = useLocation();

  const handlePhoneInputFocus = () => {
    setPhoneInputClass({
      inputClass: styles.PhoneInputFocus,
      buttonClass: styles.PhoneInputButtonFocus,
      dropdownClass: styles.PhoneInputDropdownFocus,
      searchClass: styles.PhoneInputSearchFocus,
    });
  };

  const handlePhoneInputBlur = () => {
    setPhoneInputClass({
      inputClass: styles.PhoneInput,
      buttonClass: styles.PhoneInputButton,
      dropdownClass: styles.PhoneInputDropdown,
      searchClass: styles.PhoneInputSearch,
    });
  };

  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    const codeLength = country.dialCode.length;
    const countryCode = formattedValue.substring(0, codeLength + 1);
    let number = formattedValue.substring(codeLength + 1);
    if (!number) {
      number = 0;
    }
    setMobileNum((prevState) => ({
      countryCode,
      number: parseInt(number),
    }));
  };

  return (
    <div
      className={pathname === "/signin" ? styles.InputSignInPage : styles.Input}
    >
       <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
            {labelText}
          </span>
      <PhoneInput
        countryCodeEditable={false}
        disabled={disabled}
        country={"in"}
        variant="outlined"
        inputClass={phoneInputClass.inputClass}
        buttonClass={phoneInputClass.buttonClass}
        dropdownClass={phoneInputClass.dropdownClass}
        searchClass={phoneInputClass.searchClass}
        placeholder="xxxxx xxxxx"
        autoFormat={false}
        onChange={handlePhoneInputChange}
        onFocus={handlePhoneInputFocus}
        onBlur={handlePhoneInputBlur}
        inputProps={{
          name: labelText,
          autoComplete: "tel",
          required: true,
        }}
        value={value}
      />
    </div>
  );
};

export default PhoneInputComponent;
