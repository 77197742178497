import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  setGetUser,
  setGetUserError,
  setGetUserSuccess,
  setUpdateUser,
  setUpdateUserError,
  setUpdateUserSuccess,
} from "../Redux/user/actions";
import { getUser, updateUser } from "../Services/user/user";
import notify from "../Utils/notifyToast";
import useAxiosPrivate from "./useAxiosPrivate";

function useUser() {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const handleGetUser = useCallback(async () => {
    try {
      dispatch(setGetUser());
      const response = await getUser(axiosPrivate);
      dispatch(setGetUserSuccess(response.data));
    } catch (error) {
      notify(
        error?.response?.data?.errors?.[0]?.message
          ? error?.response?.data?.errors?.[0]?.message
          : "Some server error occured, please try again later!",
        "error"
      );
      dispatch(
        setGetUserError(
          error?.response?.data?.errors?.[0]
            ? error?.response?.data?.errors?.[0]
            : "Some server error occured, please try again later!"
        )
      );
    }
  }, [dispatch]);

  const handleUpdateUser = useCallback(
    async (userDetails) => {
      dispatch(setUpdateUser());
      try {
        const response = updateUser(axiosPrivate, userDetails);
        notify("Successfully updated user data", "success");
        dispatch(setUpdateUserSuccess(response.data.message));
      } catch (error) {
        dispatch(
          setUpdateUserError(
            error?.response?.data?.errors?.[0]
              ? error?.response?.data?.errors?.[0]
              : "Some server error occured, please try again later!"
          )
        );
      }
    },
    [dispatch]
  );
  return {
    handleGetUser,
    handleUpdateUser,
  };
}

export default useUser;
