import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAccount from "../../../Hooks/useAccount";
import useUser from "../../../Hooks/useUser";
import Button from "../../Button/Button";
import Input from "../../Input";
import staticData from "../../staticData";
import styles from "./AccountSettings.module.css";

const content = staticData.AccountPage.AccountSettings;

const AccountSettings = () => {
  const { handleGetUser } = useUser();
  const { userData, loading } = useSelector((state) => state.user);
  const { account: accountData } = useSelector((state) => state.account);
  const { handleUpdateUser } = useUser();
  const { handleAccount } = useAccount();
  const [isInputReadOnly, setIsInputReadOnly] = useState(true);

  const [accountDetails, setAccountDetails] = useState({
    firstName: "",
    lastName: "",
    organizationName: "",
    email: "",
    mobileNum: "",
  });

  const handleInputChange = ({ target, target: { name, value } }) => {
    setAccountDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  // const handleEdit = (e) => {
  //   const name = e.currentTarget.name;
  //   setIsInputEditable((prevState) => ({
  //     ...prevState,
  //     [name]: false,
  //   }));
  // };

  const handleEdit = () => {
    setIsInputReadOnly(false);
  };

  const handleSaveDetails = () => {
    handleUpdateUser(accountDetails);
  };

  useEffect(() => {
    handleGetUser();
    handleAccount();
  }, []);

  useEffect(() => {
    if (userData && accountData) {
      setAccountDetails((prevState) => ({
        ...prevState,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        organizationName: accountData.organization.name,
        mobileNum: userData.mobile.number,
      }));
    }
  }, [userData, accountData]);

  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Heading}>{content.heading}</h1>
      <div className={styles.InputWrapper}>
        <div className={styles.LabelInputWrapper}>
          <label className={styles.Label} htmlFor="first name">
            First Name
          </label>
          <Input
            value={accountDetails.firstName}
            name={"firstName"}
            readOnly={isInputReadOnly}
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.LabelInputWrapper}>
          <label className={styles.Label} htmlFor="last name">
            Last Name
          </label>
          <Input
            value={accountDetails.lastName}
            name={"lastName"}
            readOnly={isInputReadOnly}
            onChange={handleInputChange}
          />
        </div>

        {/* <Button
          className={styles.Btn}
          btnType={"primary"}
          text={content.editBtnText}
          onClick={handleEdit}
          name="name"
        /> */}
      </div>
      <div className={styles.InputWrapper}>
        <div className={styles.LabelInputWrapper}>
          <label className={styles.Label} htmlFor="org name">
            Organization Name
          </label>
          <Input
            value={accountDetails.organizationName}
            name={"organizationName"}
            readOnly={true}
            onChange={handleInputChange}
            className={styles.DisabledInput}
          />
        </div>
        {/* <Button
          className={styles.Btn}
          btnType={"primary"}
          text={content.editBtnText}
          onClick={handleEdit}
          name="organizationName"
        /> */}
      </div>
      <div className={styles.InputWrapper}>
        <div className={styles.LabelInputWrapper}>
          <label className={styles.Label} htmlFor="email">
            Email Address
          </label>
          <Input
            value={accountDetails.email}
            readOnly={true}
            onChange={handleInputChange}
            name="email"
            className={styles.DisabledInput}
          />
        </div>{" "}
        {/* <Button
          className={styles.Btn}
          btnType={"primary"}
          text={content.changeBtnText}
          name={"email"}
          onClick={handleEdit}
        /> */}
      </div>
      <div className={styles.InputWrapper}>
        <div className={styles.LabelInputWrapper}>
          <label className={styles.Label} htmlFor="">
            Moblie Number (Optional)
          </label>
          <Input
            value={accountDetails.mobileNum}
            readOnly={true}
            onChange={handleInputChange}
            name="mobileNum"
            className={styles.DisabledInput}
          />
        </div>{" "}
        {/* <Button
          className={styles.Btn}
          btnType={"primary"}
          text={content.changeBtnText}
          name={"mobileNum"}
          onClick={handleEdit}
        /> */}
      </div>
      <div className={styles.BtnWrapper}>
        <Button text={"Edit"} btnType="primary" onClick={handleEdit} />
        <Button
          text={"Save"}
          btnType="primary"
          onClick={handleSaveDetails}
          isLoading={loading}
          isDisabled={loading}
        />
      </div>
    </div>
  );
};

export default AccountSettings;
