import { useSelector } from "react-redux";
import LayoutComponent from "../../../Components/Onboarding/LayoutComponent";
import OtpComponent from "../../../Components/Onboarding/OtpComponent";
import useAuth from "../../../Hooks/useAuth";
import staticData from "../../../Components/staticData";
import styles from "./OTPPage.module.css";

const signInPage = staticData.signInUpPage;

const OTPPage = () => {
  const { handleVerifyOTP, handleResendOtp } = useAuth();
  const { email } = useSelector((state) => state.auth);

  const authFunction = (otp) => {
    handleVerifyOTP(otp, email);
  };

  const handleResendSignInOtp = () => {
    handleResendOtp(email);
  };

  return (
    <div className={styles.Wrapper}>
      <LayoutComponent
        renderComp={
          <OtpComponent
            authFunction={authFunction}
            title={"Enter OTP"}
            buttonTxt={"Sign In"}
            handleResendOtp={handleResendSignInOtp}
            email={email}
          />
        }
        headerText={signInPage.signIn.subTitle[0]}
        headerLinkText={signInPage.signIn.subTitle[1]}
        headerLink={"/signup"}
      />
    </div>
  );
};

export default OTPPage;
