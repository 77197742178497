import DashboardIcon from "../Assets/SideMenu/DashboardIcon.svg";
import ProfileIcon from "../Assets/SideMenu/ProfileIcon.svg";
import KeyIcon from "../Assets/SideMenu/KeyIcon.svg";
import DocsIcon from "../Assets/SideMenu/DocsIcon.svg";
import MessageIcon from "../Assets/SideMenu/MessageIcon.svg";

//SignInUpPage
import GoogleIcon from "../Assets/SignInUpPage/GoogleLogo.svg";
import BackgroundImage from "../Assets/SignInUpPage/Background.svg";
import LayoutAsset from "../Assets/SignInUpPage/LayoutAsset.svg";

import logo from "../Assets/General/Logo.svg";
import defaultProfilePicture from "../Assets/General/DefaultProfilePicture.svg";
import profilePicture from "../Assets/General/ProfilePicture.svg";
import { ReactComponent as DownArrow } from "../Assets/General/DownArrow.svg";
import { ReactComponent as FilledDownArrow } from "../Assets/General/FilledDownArrow.svg";

import CheckMarkGreenBg from "../Assets/General/CheckMarkGreenBg.svg";
import WarningIcon from "../Assets/General/WarningIcon.svg";

export const staticData = {
  navbar: {
    categories: [
      // {
      //   name: "dashboard",
      //   path: ["/dashboard"],
      // },

      {
        name: "Clients",
        path: ["/clients"],
      },
      {
        name: "Reports",
        path: ["/reports"],
      },
      {
        name: "Requests",
        path: ["/requests"],
      },
      {
        type: "anchor",
        name: "Helpdesk",
        path: "https://tax-support.koinx.com/",
      },
      {
        type: "anchor",
        name: "Blog",
        path: "https://blog.koinx.com",
      },
    ],
    profile: {
      icon: profilePicture,
      name: "profile",
      path: "/settings",
    },
    profileDropdown: {
      dropdownList: [
        {
          label: "Settings",
          path: "/settings",
        },

        // {
        //   label: "Contact Us",
        //   path: "/contact",
        // },
      ],
      signout: {
        label: "Signout",
      },
    },
  },
  AccountPending: {
    heading: "Account Creation Pending...",
    descriptionOne:
      "We have received your request. We will complete the set up and you will receive an email",
    descriptionTwo: "Any further queries? Please reach out to ",
    highlightedText: "support@koinx.com",
  },
  signInUpPage: {
    BackgroundImage,
    logo,
    LayoutAsset,
    layoutComponentTitle:
      "Calculate your crypto taxes of your customers in no time",
    orText: "or",
    googleText: "Continue with Google",
    googleIcon: GoogleIcon,

    businessText: "Not a business user?",
    signUp: {
      verifyOtpPage: {
        title: "Verify Account",
        btnText: "Create Account",
      },
      subTitle: ["Already have an account?", "Sign In"],
      title: ["Sign Up", "Create Your", "Account"],
      button: {
        googleButton: { text: "SignUp with Google", icon: GoogleIcon },
        continueButton: { text: "Continue" },
        otpButton: { text: "Send Verification Code" },
      },
      orContent: "Or",
      inputRequired: [
        {
          label: "First Name",
          type: "text",
          name: "firstName",
        },
        {
          label: "Last Name",
          type: "text",
          name: "lastName",
        },
        {
          label: "Email",
          type: "email",
          name: "email",
        },
        {
          label: "Organization",
          type: "text",
          name: "caFirmName",

          isOptional: true,
        },
        {
          label: "Referral Code",
          type: "text",
          name: "campaignCode",

          isOptional: true,
        },
        {
          label: "Mobile Number",
          type: "text",
          name: "mobileNumber",
        },
      ],
      passwordText: {
        "Too weak": "var(--primary-red)",
        Weak: "var(--loss-red)",
        Medium: "var(--warning-yellow)",
        Strong: "var(--success-green)",
      },
      footerText: [
        "By signing up, I accept KoinX's ",
        "terms of use ",
        "and ",
        "privacy policy",
      ],
    },
    signIn: {
      subTitle: ["Not a member?", "Sign Up"],
      title: "Sign In",
      button: "Send Verification Code",
      inputRequired: [
        {
          label: "Email",
          type: "email",
          name: "email",
        },
      ],
    },
    otpVerify: {
      subTitle: "It has been sent to you on your registered email and phone",
      label: "Enter 6 Digit OTP",
      footerText: "Didn’t Receive?",
      linkText: "Resend",
    },
  },
  SideMenu: {
    options: [
      {
        btnText: "Dashboard",
        route: "",
        key: "/",
        image: DashboardIcon,
      },
      {
        btnText: "Account",
        route: "account",
        key: "account",
        image: ProfileIcon,
      },
      {
        btnText: "Api Key",
        route: "apikey",
        key: "apikey",
        image: KeyIcon,
      },
      {
        btnText: "API Documentation",
        route: "",
        key: "",
        image: DocsIcon,
      },
      {
        btnText: "Contact Us",
        route: "contact",
        key: "contact",
        image: MessageIcon,
      },
    ],
  },
  UsageOverviewComponent: {
    heading: "Overview Usage",
    subHeading: "Credits remaining ",
    creditsUtilized: "Credits Utilized: 36,000",
    totalCredits: "Total Credits: 1,00,000",
    tableContent: {
      transactionProcessed: "Transactions processed",
      usersCreated: "Users created",
      taxReportsGenerated: "Tax reports generated",
    },
  },
  ContactUsPage: {
    heading: "Contact us",
    subHeading:
      "Lorem ipsum dolor sit amet consectetur. Nunc nunc ornare id adipiscing leo.",
    contactEmail: "enterprise@koinx.com",
    contactNumber: "9876543210",
  },
  ApiKeyPage: {
    ApiKey: {
      heading: "API Key",
      refreshBtnText: "Refresh Keys",
      disableBtnText: "Disable API Key",
      enableBtnText: "Enable  API Key",
    },
    GenerateApiKey: {
      heading: "API Key",
      genrateBtnText: "+ Generate New",
    },
    ApiKeyPopup: {
      heading: "API Key & Secret Key",
      apiKeyText: "API Key",
      secretKeyText: "Secret Key",
      copyBtnText: "Copy",
      note: "Note: This key will be hidden forever after you refresh the page. Kindly save it securely",
      downloadBtnText: "Download txt",
      finishBtnText: "Finish",
    },
    RefreshKeyWarningPopup: {
      heading: "Are you sure you want to Refresh Keys?",
      subHeading:
        "By refreshing, current keys will be deleted and new keys will be generated",
      closeBtnText: "Close",
      refreshBtnText: "Refresh",
    },
  },

  AccountPage: {
    WebhookStatusPopup: {
      Success: {
        heading: "Setup successful",
        subHeading: "Your webhook URL has been set successfully",
        btnText: "Close",
        img: CheckMarkGreenBg,
      },
      Failure: {
        heading: "Setup Failed",
        subHeading: "Your webhook URL setup has been failed. Please try again",
        btnText: "Close",
        img: WarningIcon,
      },
    },
    AccountSettings: {
      heading: "Account",
      editBtnText: "Edit",
      changeBtnText: "Change",
    },
    ListenEvents: {
      heading: "Listen to KoinX events",
      note: "See how to setup webhook URL",
      editBtnText: "Edit",
    },
  },
};

export const images = {
  logo,
  defaultProfilePicture,
  profilePicture,
  FilledDownArrow,
  DownArrow,
  // Calender,
  // PNGLogo,
  // Quicko,
};

export default staticData;
