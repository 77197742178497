import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import {
  setSignup,
  setSignupError,
  setSignupSuccess,
  setVerifyOTP,
  setVerifyOTPSuccess,
  setVerifyOTPError,
  setSignin,
  setSigninSuccess,
  setSigninError,
  setHandleUserTokens,
  setRefreshToken,
  setRefreshTokenSuccess,
  setRefreshTokenError,
  setLogout,
  setLogoutSuccess,
  setLogoutError,
  // setResendOTP,
  // setResendOTPSuccess,
  // setResendOTPError,
} from "../Redux/auth/actions";
import { signup, singup } from "../Services/auth/signup";
import { verifyOTP } from "../Services/auth/verifyOTP";
import { signin } from "./../Services/auth/singin";
import { refreshToken } from "../Services/auth/refreshToken";
import { logout } from "../Services/auth/logout";
import notify from "../Utils/notifyToast";

function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleSignUp = useCallback(
    (
      { firstName, lastName, email, mobile: contact, organizationName },
      token,
      recaptchaRef
    ) => {
      dispatch(setSignup());
      signup(firstName, lastName, email, contact, organizationName, token)
        .then((response) => {
          dispatch(setSignupSuccess(response.data.message, email, contact));
          navigate("/verifyotp");
          notify("OTP sent successfully", "success");
        })
        .catch((error) => {
          dispatch(
            setSignupError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, navigate]
  );

  const handleVerifyOTP = useCallback(
    (otp, email) => {
      dispatch(setVerifyOTP());
      verifyOTP(otp, email)
        .then((response) => {
          dispatch(setVerifyOTPSuccess(response.data.message));
          dispatch(setHandleUserTokens(response.data.accessToken));
          navigate("/");
          notify("Onboarding successfull", "success");
        })
        .catch((error) => {
          dispatch(
            setVerifyOTPError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, navigate]
  );

  const handleSignIn = useCallback(
    (email, token) => {
      dispatch(setSignin());
      signin(email, token)
        .then((response) => {
          dispatch(setSigninSuccess(response.data.message, email));
          navigate("/otp");
          notify("OTP sent successfully", "success");
        })
        .catch((error) => {
          dispatch(
            setSigninError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, navigate]
  );

  // const handleResendOTP = useCallback(
  //   (email, mobile, userId) => {
  //     dispatch(setResendOTP());
  //     gaEventTracker("Resend OTP Clicked", "Resend OTP Clicked");
  //     resendOTP(email, mobile, userId)
  //       .then((response) => {
  //         gaEventTracker("Resend OTP Success", "Resend OTP Success");
  //         dispatch(setResendOTPSuccess(response.data.message));
  //         notify("OTP resend successfull", "success");
  //       })
  //       .catch((error) => {
  //         gaEventTracker("Resend OTP Error", "Resend OTP Error");
  //         dispatch(
  //           setResendOTPError(
  //             error?.response?.data?.errors?.[0]
  //               ? error?.response?.data?.errors?.[0]
  //               : "Some server error occured, please try again later!"
  //           )
  //         );
  //         notify(
  //           error?.response?.data?.errors?.[0]?.message
  //             ? error?.response?.data?.errors?.[0]?.message
  //             : "Some server error occured, please try again later!",
  //           "error"
  //         );
  //       });
  //   },
  //   [dispatch]
  // );

  const handleRefreshToken = useCallback(
    (setIsAppInitalized) => {
      dispatch(setRefreshToken());

      refreshToken()
        .then((response) => {
          dispatch(setRefreshTokenSuccess(response.data.message));
          dispatch(setHandleUserTokens(response.data.accessToken));
          setIsAppInitalized(true);
        })
        .catch((error) => {
          dispatch(
            setRefreshTokenError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          if (["/signin", "/signup"].includes(window.location.pathname))
            return setIsAppInitalized(true);
          setIsAppInitalized(true);
          // navigate("/");
        });
    },
    [dispatch, navigate, location]
  );

  const handleUserLogout = useCallback(() => {
    dispatch(setLogout());
    logout()
      .then((response) => {
        dispatch(setLogoutSuccess(response.data.message));
        dispatch(setHandleUserTokens(null));
        notify("Logout successfull", "success");
        navigate(0);
      })
      .catch((error) => {
        dispatch(
          setLogoutError(
            error?.response?.data?.errors?.[0]
              ? error?.response?.data?.errors?.[0]
              : "Some server error occured, please try again later!"
          )
        );
        notify(
          error?.response?.data?.errors?.[0]?.message
            ? error?.response?.data?.errors?.[0]?.message
            : "Some server error occured, please try again later!",
          "error"
        );
      });
  }, [dispatch, navigate]);

  return {
    handleSignUp,
    handleVerifyOTP,
    handleSignIn,
    handleRefreshToken,
    handleUserLogout,
  };
}

export default useAuth;
