import React, { useState, useCallback, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../Button";
import useAuth from "../../../Hooks/useAuth";
import { useSelector } from "react-redux";
import staticData from "../../staticData";
import "react-phone-input-2/lib/style.css";
import styles from "./SignInComponent.module.css";

const signInPage = staticData.signInUpPage;

const SignInComponent = () => {
  const [recaptchaToken, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const { loading } = useSelector((state) => state.auth);
  const { handleSignIn } = useAuth();
  const recaptchaRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignIn(email, recaptchaToken, recaptchaRef);
    setToken(null);
  };

  const handleVerify = useCallback(
    (recaptchaToken) => {
      setToken(recaptchaToken);
    },
    [setToken]
  );

  const handleExpired = () => {
    setToken(null);
  };

  const handleChange = (e) => {
    setEmail(() => e.target.value);
  };

  return (
    <div className={styles.Wrapper}>
      <h2 className={styles.Heading}>{signInPage.signIn.title}</h2>
      <form className={styles.Form} onSubmit={handleSubmit}>
        <div className={styles.Input}>
          <span className={styles.FormHeading}>
            {signInPage.signIn.inputRequired[0].label}
          </span>
          <input
            onChange={handleChange}
            name="email"
            className={styles.CodeInput}
            autoFocus={true}
          />
        </div>
        <div className={styles.RecaptchaWrapper}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={handleVerify}
            onExpired={handleExpired}
          />
        </div>
        <Button
          text={signInPage.signIn.button}
          className={styles.Button}
          disabledClassName={styles.SendBtnDisabled}
          isDisabled={!(recaptchaToken && email) || loading}
          isLoading={loading}
        />
      </form>
    </div>
  );
};

export default SignInComponent;
