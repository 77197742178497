import {
  GET_ACCOUNT,
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
  ACCOUNT_KEYS,
  ACCOUNT_KEYS_ERROR,
  ACCOUNT_KEYS_SUCCESS,
  ACCOUNT_KEYS_TOGGLE,
  ACCOUNT_KEYS_TOGGLE_ERROR,
  ACCOUNT_KEYS_TOGGLE_SUCCESS,
  ACCOUNT_WEBHOOK,
  ACCOUNT_WEBHOOK_ERROR,
  ACCOUNT_WEBHOOK_SUCCESS,
} from "./types";

const initialState = {
  account: null,
  loading: false,
  error: null,
  message: null,
  apiSecretKey: null,
  apiKey: null,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_KEYS:
    case GET_ACCOUNT:
    case ACCOUNT_KEYS_TOGGLE:
    case ACCOUNT_WEBHOOK: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACCOUNT_KEYS_TOGGLE_SUCCESS:
    case ACCOUNT_WEBHOOK_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.message,
      };
    }
    case ACCOUNT_KEYS_ERROR:
    case ACCOUNT_KEYS_TOGGLE_ERROR:
    case ACCOUNT_WEBHOOK_ERROR:
    case GET_ACCOUNT_ERROR: {
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    }
    case ACCOUNT_KEYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.message,
        apiKey: action.payload.apiKey,
        apiSecretKey: action.payload.secretKey,
      };
    }

    case GET_ACCOUNT_SUCCESS: {
      return {
        ...state,
        account: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export { accountReducer };
