export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR";

export const ACCOUNT_KEYS = "ACCOUNT_KEYS";
export const ACCOUNT_KEYS_SUCCESS = "ACCOUNT_KEYS_SUCCESS";
export const ACCOUNT_KEYS_ERROR = "ACCOUNT_KEYS_ERROR";

export const ACCOUNT_KEYS_TOGGLE = "ACCOUNT_KEYS_TOGGLE";
export const ACCOUNT_KEYS_TOGGLE_SUCCESS = "ACCOUNT_KEYS_TOGGLE_SUCCESS";
export const ACCOUNT_KEYS_TOGGLE_ERROR = "ACCOUNT_KEYS_TOGGLE_ERROR";

export const ACCOUNT_WEBHOOK = "ACCOUNT_WEBHOOK";
export const ACCOUNT_WEBHOOK_SUCCESS = "ACCOUNT_WEBHOOK_SUCCESS";
export const ACCOUNT_WEBHOOK_ERROR = "ACCOUNT_WEBHOOK_ERROR";
