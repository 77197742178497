import {
  HANDLE_POPUP_OPEN,
  HANDLE_POPUP_COMPONENT_RENDER,
  HANDLE_POPUP_CENTER_OPEN,
  HANDLE_POPUP_CENTER_COMPONENT_RENDER,
} from "./types";

const popupReducer = (
  state = {
    popupOpen: false,
    popupComponent: null,
    popupCenterOpen: false,
    popupCenterComponent: null,
  },
  action
) => {
  switch (action.type) {
    case HANDLE_POPUP_OPEN: {
      return {
        ...state,
        popupOpen: action.popupOpen,
      };
    }
    case HANDLE_POPUP_COMPONENT_RENDER: {
      return {
        ...state,
        popupComponent: action.popupComponent,
      };
    }
    // popup_center
    case HANDLE_POPUP_CENTER_OPEN: {
      return {
        ...state,
        popupCenterOpen: action.popupCenterOpen,
      };
    }
    case HANDLE_POPUP_CENTER_COMPONENT_RENDER: {
      return {
        ...state,
        popupCenterComponent: action.popupCenterComponent,
      };
    }
    default:
      return state;
  }
};

export { popupReducer };
