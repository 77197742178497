import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar";

import SignUp from "./Containers/Onboarding/SignUp";
import SignIn from "./Containers/Onboarding/SignIn";
import OTPPage from "./Containers/Onboarding/OTPPage";
import VerifyOTPPage from "./Containers/Onboarding/VerifyOTPPage";
import { ToastContainer } from "react-toastify";
import PopUp from "./Components/PopUp";
import PopUpCenter from "./Components/PopUpCenter";
import AccountPage from "./Containers/AccountPage";
import ApiKeyPage from "./Containers/ApiKeyPage";
import ContactUsPage from "./Containers/ContactUsPage/ContactUsPage";
import DashboardPage from "./Containers/DashboardPage/DashboardPage";
import usePopup from "./Hooks/usePopup";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "./Hooks/useAuth";
import RequiresAuth from "./Components/RequiresAuth/RequiresAuth";

const App = () => {
  const {
    popupComponent: componentToRender,
    popupOpen: popUpState,
    popupCenterComponent: componentToShow,
    popupCenterOpen: popUpCenterState,
  } = useSelector((state) => state.popup);
  const { handlePopupOpen, handlePopupCenterOpen } = usePopup();
  const [isAppInitialized, setIsAppInitialized] = useState(false);

  const { accessToken } = useSelector((state) => state.auth);
  const { handleRefreshToken } = useAuth();
  const location = useLocation();

  // Fetching accessToken from refreshToken
  useEffect(() => {
    if (!accessToken) {
      handleRefreshToken(setIsAppInitialized);
    }
  }, [accessToken]);

  return (
    <>
      <ToastContainer
        bodyClassName="ToastifyToastBody"
        style={{ fontSize: "1.5rem" }}
      />
      <PopUp
        ContentComp={componentToRender}
        isOpen={popUpState}
        closeFun={() => {
          handlePopupOpen(false);
        }}
      />
      <PopUpCenter
        ContentComp={componentToShow}
        isOpen={popUpCenterState}
        closeFun={() => {
          handlePopupCenterOpen(false);
        }}
      />
      {isAppInitialized ? (
        <div>
          {location.pathname.includes("/signin") ||
          location.pathname.includes("/signup") ||
          location.pathname.includes("/otp") ||
          location.pathname.includes("/verifyotp") ? null : (
            <Navbar />
          )}

          <Routes>
            <Route
              exact
              path="/"
              element={
                <RequiresAuth>
                  <DashboardPage />
                </RequiresAuth>
              }
            />
            ;
            <Route exact path="/contact" element={<ContactUsPage />} />;
            <Route
              exact
              path="/apikey"
              element={
                <RequiresAuth>
                  <ApiKeyPage />
                </RequiresAuth>
              }
            />
            ;
            <Route
              exact
              path="/account"
              element={
                <RequiresAuth>
                  <AccountPage />
                </RequiresAuth>
              }
            />
            ;
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/otp" element={<OTPPage />} />
            <Route path="/verifyotp" element={<VerifyOTPPage />} />
          </Routes>
        </div>
      ) : null}
    </>
  );
};

export default App;
