import {
  GET_USER,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from "./types";

export function setGetUser() {
  return {
    type: GET_USER,
  };
}

export function setGetUserSuccess(userData) {
  return {
    type: GET_USER_SUCCESS,
    payload: userData,
  };
}

export function setGetUserError(message) {
  return {
    type: GET_USER_ERROR,
    payload: message,
  };
}

export function setUpdateUser(message) {
  return {
    type: UPDATE_USER,
    payload: message,
  };
}

export function setUpdateUserSuccess(message) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: message,
  };
}

export function setUpdateUserError(message) {
  return {
    type: UPDATE_USER_ERROR,
    payload: message,
  };
}
