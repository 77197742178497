import {
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  HANDLE_USER_TOKENS,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} from "./types";

const initialState = {
  accessToken: null,
  contact: null,
  loading: false,
  error: null,
  email: null,
  message: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP:
    case SIGN_IN:
    case VERIFY_OTP:
    case RESEND_OTP:
    case LOGOUT:
    case REFRESH_TOKEN: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGN_UP_SUCCESS:
    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.message,
        contact: action.contact,
        email: action.email,
      };
    }
    case LOGOUT_SUCCESS:
    case REFRESH_TOKEN_SUCCESS:
    case VERIFY_OTP_SUCCESS:
    case RESEND_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    }
    case LOGOUT_ERROR:
    case RESEND_OTP_ERROR:
    case SIGN_IN_ERROR:
    case VERIFY_OTP_ERROR:
    case SIGN_UP_ERROR:
    case REFRESH_TOKEN_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case HANDLE_USER_TOKENS: {
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      };
    }
    default:
      return state;
  }
};

export { authReducer };
