import AccountPending from "../../Components/AccountPending";
import UsageOverviewComponent from "../../Components/Dashboard/UsageOverviewComponent/UsageOverviewComponent";
import SideMenu from "../../Components/SideMenu";
import styles from "./DashboardPage.module.css";

const DashboardPage = () => {
  return false ? (
    <div className={styles.AccPendingWrapper}>
      <AccountPending />
    </div>
  ) : (
    <div className={styles.Wrapper}>
      <SideMenu />
      <UsageOverviewComponent />
    </div>
  );
};

export default DashboardPage;
