import React from "react";
import { Link } from "react-router-dom";
import staticData from "../../staticData";
import styles from "./LayoutComponent.module.css";

const signInPage = staticData.signInUpPage;

const LayoutComponent = ({
  renderComp,
  headerText,
  headerLinkText,
  headerLink,
}) => {
  return (
    <div className={styles.Wrapper}>
      <img src={signInPage.logo} alt="Logo" className={styles.UpperLogo} />
      <div className={styles.LeftWrapper}>
        <img src={signInPage.logo} alt="Logo" className={styles.Logo} />
        <div className={styles.Title}>{signInPage.layoutComponentTitle}</div>
        <img
          src={signInPage.LayoutAsset}
          alt="Layout Asset"
          className={styles.LayoutAsset}
        />
      </div>
      <div className={styles.RightWrapper}>
        <p className={styles.RightWrapperHeader}>
          <span>{headerText ? headerText : ""}</span>{" "}
          <Link to={headerLink} className={styles.RightWrapperHeaderLink}>
            {headerLinkText ? headerLinkText : ""}
          </Link>
        </p>
        <div className={styles.RenderComponentWrapper}>
          {renderComp ? renderComp : null}
        </div>
      </div>
    </div>
  );
};

export default LayoutComponent;
