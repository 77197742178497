import { Close } from "@mui/icons-material";
import usePopup from "../../../Hooks/usePopup";
import Button from "../../Button/Button";
import staticData from "../../staticData";
import styles from "./WebhookStatusPopup.module.css";

const contentSuccess = staticData.AccountPage.WebhookStatusPopup.Success;
const contentFailure = staticData.AccountPage.WebhookStatusPopup.Failure;

const WebhookStatusPopup = ({ failure }) => {
  const { handlePopupCenterOpen } = usePopup();

  const closePopup = () => {
    handlePopupCenterOpen(false);
  };

  return failure ? (
    <div className={styles.FailWrapper}>
      <Close className={styles.CloseIcon} onClick={closePopup} />
      <img
        className={styles.StatusIcon}
        src={contentFailure.img}
        alt="failure"
      />
      <h1 className={styles.Heading}>{contentFailure.heading}</h1>
      <p className={styles.SubHeading}>{contentFailure.subHeading}</p>
      <Button className={styles.CloseBtn} text={contentFailure.btnText} />
    </div>
  ) : (
    <div className={styles.SuccessWrapper}>
      <Close className={styles.CloseIcon} onClick={closePopup} />
      <img
        className={styles.StatusIcon}
        src={contentSuccess.img}
        alt="warning"
      />
      <h1 className={styles.Heading}>{contentSuccess.heading}</h1>
      <p className={styles.SubHeading}>{contentSuccess.subHeading}</p>
      <Button
        className={styles.CloseBtn}
        text={contentSuccess.btnText}
        onClick={closePopup}
      />
    </div>
  );
};

export default WebhookStatusPopup;
