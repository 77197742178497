import { useState } from "react";
import styles from "./Input.module.css";

const Input = ({
  className,
  initialInputValue,
  readOnly = false,
  onChange,
  value,
  name,
}) => {
  const [localValue, setValue] = useState(
    initialInputValue ? initialInputValue : ""
  );
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <input
      name={name}
      className={`${styles.DefaultClassName} ${className ? className : ""}`}
      type="text"
      value={value ? value : localValue}
      onChange={onChange ? onChange : handleChange}
      readOnly={readOnly}
    />
  );
};

export default Input;
