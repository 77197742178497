import LayoutComponent from "../../../Components/Onboarding/LayoutComponent";
import SignInComponent from "../../../Components/Onboarding/SignInComponent";
import staticData from "../../../Components/staticData";
import styles from "./SignIn.module.css";

const signInPage = staticData.signInUpPage;

const SignIn = () => {
  return (
    <div className={styles.Wrapper}>
      <LayoutComponent
        renderComp={<SignInComponent />}
        headerText={signInPage.signIn.subTitle[0]}
        headerLinkText={signInPage.signIn.subTitle[1]}
        headerLink={"/signup"}
      />
    </div>
  );
};

export default SignIn;
