import { useEffect } from "react";
import { useSelector } from "react-redux";
import ApiKey from "../../Components/ApiKey/ApiKey";
import GenerateApiKey from "../../Components/ApiKey/GenerateApiKey";
import SideMenu from "../../Components/SideMenu/SideMenu";
import useAccount from "../../Hooks/useAccount";
import styles from "./ApiKeyPage.module.css";

const ApiKeyPage = () => {
  const { handleAccount } = useAccount();

  const { account } = useSelector((state) => state.account);

  useEffect(() => {
    handleAccount();
  }, []);

  return (
    <div className={styles.Wrapper}>
      <SideMenu />
      {account?.apiKey ? <ApiKey /> : <GenerateApiKey />}
    </div>
  );
};

export default ApiKeyPage;
