import ContactUs from "../../Components/ContactUs";
import SideMenu from "../../Components/SideMenu";
import styles from "./ContactUsPage.module.css";

const ContactUsPage = () => {
  return (
    <div className={styles.Wrapper}>
      <SideMenu />
      <ContactUs />
    </div>
  );
};

export default ContactUsPage;
