import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import WarningIcon from "../../../Assets/General/WarningIcon.svg";
import useAccount from "../../../Hooks/useAccount";
import usePopup from "../../../Hooks/usePopup";
import Button from "../../Button/Button";
import staticData from "../../staticData";
import styles from "./RefreshKeyWarningPopup.module.css";

const content = staticData.ApiKeyPage.RefreshKeyWarningPopup;

const RefreshKeyWarningPopup = () => {
  const { handlePopupCenterOpen } = usePopup();
  const { handleRefreshAccountKeys } = useAccount();

  const { loading } = useSelector((state) => state.account);

  const handleClose = () => {
    handlePopupCenterOpen(false);
  };

  const handleRefresh = () => {
    handleRefreshAccountKeys();
  };

  return (
    <div className={styles.Wrapper}>
      <Close className={styles.CloseIcon} onClick={handleClose} />
      <img src={WarningIcon} alt="warning" />
      <h1 className={styles.Heading}>{content.heading}</h1>
      <p className={styles.SubHeading}>{content.subHeading}</p>
      <div className={styles.BtnWrapper}>
        <Button
          text={content.closeBtnText}
          className={styles.Btn}
          onClick={handleClose}
        />
        <Button
          text={content.refreshBtnText}
          className={styles.Btn}
          btnType={"primary"}
          onClick={handleRefresh}
          isLoading={loading}
          isDisabled={loading}
        />
      </div>
    </div>
  );
};

export default RefreshKeyWarningPopup;
