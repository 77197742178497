import AccountSettings from "../../Components/Account/AccountSettings/AccountSettings";
import ListenEvents from "../../Components/Account/ListenEvents";
import SideMenu from "../../Components/SideMenu";
import styles from "./AccountPage.module.css";

const AccountPage = () => {
  return (
    <div className={styles.Wrapper}>
      <SideMenu />
      <div className={styles.InnerWrapper}>
        <AccountSettings />
        <ListenEvents />
      </div>
    </div>
  );
};

export default AccountPage;
