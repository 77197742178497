import axios from "../axios";
import { SIGNUP_URL } from "../../Utils/constants";

export function signup(
  firstName,
  lastName,
  email,
  mobile,
  organizationName,
  recaptchaToken
) {
  return axios.post(SIGNUP_URL, {
    firstName,
    lastName,
    email,
    mobile,
    organizationName,
    recaptchaToken,
  });
}
