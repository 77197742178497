import staticData from "../staticData";
import BlueMessageIcon from "../../Assets/General/BlueMessageIcon.svg";
import Call from "../../Assets/General/Call.svg";
import styles from "./ContactUs.module.css";

const content = staticData.ContactUsPage;

const ContactUs = () => {
  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Heading}>{content.heading}</h1>
      <p className={styles.SubHeading}>{content.subHeading}</p>
      <div className={styles.InfoWrapper}>
        <img src={BlueMessageIcon} alt="Email" />
        <span className={styles.InfoText}>{content.contactEmail}</span>
      </div>
      <div className={styles.InfoWrapper}>
        <img src={Call} alt="Call" />
        <span className={styles.InfoText}>{content.contactNumber}</span>
      </div>
    </div>
  );
};

export default ContactUs;
