export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const PRO_URL = `${BASE_URL}/pro`;

//Auth URLs
export const LOGOUT_URL = `${PRO_URL}/auth/signout`;
export const SIGNIN_URL = `${PRO_URL}/auth/signin`;
export const SIGNUP_URL = `${PRO_URL}/auth/signup`;
export const VERIFY_OTP_URL = `${PRO_URL}/auth/verifyOTP`;
export const REFRESH_TOKEN_URL = `${PRO_URL}/auth/token`;

//Account URLs
export const GET_ACCOUNT_URL = `${PRO_URL}/account`;
export const ACCOUNT_KEYS_URL = `${PRO_URL}/account/keys`;
export const TOGGLE_ACCOUNT_KEYS_URL = `${PRO_URL}/account/keys/toggle`;
export const ACCOUNT_WEBHOOK_URL = `${PRO_URL}/account/webhook`;

// User
export const USER_URL = `/user`;
