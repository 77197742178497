import React, { useEffect, useRef, useState, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../Button";
import PhoneInputComponent from "../../PhoneInputComponent";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import useAuth from "../../../Hooks/useAuth";
import staticData from "../../staticData";
import styles from "./SignUp.module.css";

const signUpPage = staticData.signInUpPage;

const SignUp = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    referrerCode: "",
    organizationName: "",
    campaignCode: "",
  });
  const [mobile, setMobile] = useState({
    countryCode: "",
    number: 0,
  });

  const { loading } = useSelector((state) => state.auth);

  const [recaptchaToken, setRecaptchaToken] = React.useState(null);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const { handleSignUp } = useAuth();

  const inputRef = useRef();
  const recaptchaRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (true) {
      handleSignUp(
        { ...userDetails, mobile: mobile },
        recaptchaToken,
        recaptchaRef
      );
    }
  };

  const handlePolicyChange = () => {
    setIsPolicyAccepted(!isPolicyAccepted);
  };

  const handleVerify = useCallback(
    (recaptchaToken) => {
      setRecaptchaToken(recaptchaToken);
    },
    [setRecaptchaToken]
  );

  const handleExpired = () => {
    setRecaptchaToken("");
  };

  const handleChange = ({ target, target: { name, value } }) => {
    setUserDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className={styles.Wrapper}>
      <h2 className={styles.FormHeader}>{signUpPage.signUp.title[0]}</h2>
      <form className={styles.FormWrapper}>
        <div className={styles.FormName}>
          <div className={styles.Input}>
            <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
              {signUpPage.signUp.inputRequired[0].label}
            </span>
            <input
              name="firstName"
              value={userDetails.firstName}
              className={styles.FormInputName}
              ref={inputRef}
              onChange={handleChange}
            />
          </div>
          <div className={styles.Input}>
            <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
              {signUpPage.signUp.inputRequired[1].label}
            </span>
            <input
              name="lastName"
              value={userDetails.lastName}
              className={styles.FormInputName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.Input}>
          <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
            {signUpPage.signUp.inputRequired[2].label}
          </span>
          <input
            name="email"
            value={userDetails.email}
            className={styles.CodeInput}
            onChange={handleChange}
          />
        </div>
        <PhoneInputComponent
          labelText={signUpPage.signUp.inputRequired[5].label}
          setMobileNum={setMobile}
          value={""}
        />
        <div className={styles.Input}>
          <span className={`${styles.FormHeading} ${styles.Mandatory}`}>
            {signUpPage.signUp.inputRequired[3].label}
          </span>
          <input
            value={userDetails.organizationName}
            onChange={handleChange}
            name="organizationName"
            className={styles.CodeInput}
          ></input>
        </div>

        {/* referall code */}
        <div className={styles.Input}>
          <span className={styles.FormHeading}>
            {signUpPage.signUp.inputRequired[4].label}
          </span>
          <input
            value={userDetails.campaignCode}
            onChange={handleChange}
            name="campaignCode"
            className={styles.CodeInput}
          ></input>
        </div>

        <div className={styles.PolicyWrapper}>
          <input
            type="checkbox"
            className={styles.PolicyCheckbox}
            onChange={handlePolicyChange}
          />
          <p className={styles.Policy}>
            {signUpPage.signUp.footerText[0]}
            <a
              href="https://www.koinx.com/terms-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.TermsLink}
            >
              {signUpPage.signUp.footerText[1]}
            </a>
            {signUpPage.signUp.footerText[2]}
            <a
              href="https://www.koinx.com/privacy-crypto-tax"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.TermsLink}
            >
              {signUpPage.signUp.footerText[3]}
            </a>
            .
          </p>
        </div>
        <div className={styles.RecaptchaWrapper}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={handleVerify}
            onExpired={handleExpired}
          />
        </div>
        <Button
          text={signUpPage.signUp.button.otpButton.text}
          className={styles.SendBtn}
          disabledClassName={styles.SendBtnDisabled}
          // isDisabled={
          //   !isPolicyAccepted ||
          //   !recaptchaToken ||
          //   !userDetails.firstName ||
          //   !userDetails.lastName ||
          //   !userDetails.email ||
          //   !userDetails.caFirmName ||
          //   loading
          // }
          isLoading={loading}
          onClick={handleSubmit}
        />
      </form>
    </div>
  );
};

export default SignUp;
