import React, { useState, useEffect, useRef, MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useAuth from "../../../Hooks/useAuth";
import Button from "../../Button";
import staticData from "../../staticData";
import styles from "./Otp.module.css";

const signUpPage = staticData.signInUpPage;

// interface IProps {
//   title: string;
//   buttonTxt: string;
//   authFunction?: (otp: string) => void;
//   handleResendOtp: any;
//   email: string;
//   mobile?: {
//     countryCode: string;
//     number: string;
//   };
// }

// interface IState {
//   otp: string;
// }

const Otp = ({
  title,
  buttonTxt,
  authFunction,
  handleResendOtp,
  email,
  mobile,
}) => {
  const [otp, setOtp] = useState("");
  const { loading } = useSelector((state) => state.auth);

  const inputRef = useRef();
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (authFunction) authFunction(otp);
  };

  const handleOtpInput = (e) => {
    const enteredOtp = e.target.value;
    setOtp(enteredOtp);
  };

  return (
    <>
      <div className={styles.Wrapper}>
        <h2 className={styles.Header}>{title}</h2>
        <div>
          <p className={styles.SubText}>{signUpPage.otpVerify.subTitle} </p>
          <div className={styles.NoContainer}>
            <span className={styles.Number}>{email}</span>
          </div>
          <br />
          {mobile ? (
            <div className={styles.NoContainer}>
              <span className={styles.Number}>{`${
                mobile?.countryCode ? mobile?.countryCode + mobile?.number : ""
              }`}</span>
            </div>
          ) : null}
        </div>
        <form className={styles.FormWrapper} onSubmit={handleSubmit}>
          <div className={styles.Input}>
            <span className={styles.FormTitle}>
              {signUpPage.otpVerify.label}
            </span>
            <input
              className={styles.FormInputOtp}
              onChange={handleOtpInput}
              maxLength={6}
              value={otp}
              required={true}
              ref={inputRef}
            />
          </div>
          <p className={styles.Resend} onClick={handleResendOtp}>
            {signUpPage.otpVerify.footerText}
            <div className={styles.ResendLink}>
              {signUpPage.otpVerify.linkText}
            </div>
          </p>
          <Button
            text={buttonTxt}
            className={styles.SendBtn}
            isLoading={loading}
            isDisabled={loading}
            disabledClassName={"DisabledBtn"}
          />
        </form>
      </div>
    </>
  );
};

export default Otp;
