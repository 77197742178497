import styles from "./Loader.module.css";

const Loader = ({ loader, isDeleteButton }) => {
  return (
    <div
      className={
        loader === "main"
          ? styles.LoaderMain
          : isDeleteButton
          ? `${styles.LoaderButton} ${styles.LoaderRedButton}`
          : styles.LoaderButton
      }
    ></div>
  );
};

export default Loader;
