import {
  HANDLE_POPUP_CENTER_COMPONENT_RENDER,
  HANDLE_POPUP_CENTER_OPEN,
  HANDLE_POPUP_COMPONENT_RENDER,
  HANDLE_POPUP_OPEN,
} from "./types";

export function setPopupOpen(popupOpen) {
  return {
    type: HANDLE_POPUP_OPEN,
    popupOpen,
  };
}

export function setPopupComponentRender(popupComponent) {
  return {
    type: HANDLE_POPUP_COMPONENT_RENDER,
    popupComponent,
  };
}

export function setPopupCenterOpen(popupCenterOpen) {
  return {
    type: HANDLE_POPUP_CENTER_OPEN,
    popupCenterOpen,
  };
}

export function setPopupCenterComponentRender(popupCenterComponent) {
  return {
    type: HANDLE_POPUP_CENTER_COMPONENT_RENDER,
    popupCenterComponent,
  };
}
