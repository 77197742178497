import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import {
  setAccount,
  setAccountError,
  setAccountSuccess,
  setAccountKeys,
  setAccountKeysSuccess,
  setAccountKeysError,
  setAccountKeysToggle,
  setAccountKeysToggleSuccess,
  setAccountKeysToggleError,
  setAccountWebhook,
  setAccountWebhookSuccess,
  setAccountWebhookError,
} from "../Redux/account/actions";
import { getAccount } from "../Services/account/account";
import { accountKeysToggle } from "../Services/account/accountKeysToggle";
import { accountKeys } from "../Services/account/accountKeys";
import { accountWebhook } from "../Services/account/accountWebhook";
import notify from "../Utils/notifyToast";
import useAxiosPrivate from "./useAxiosPrivate";
import usePopup from "./usePopup";
import ApiKeyPopup from "../Components/ApiKey/ApiKeyPopup";
import WebhookStatusPopup from "../Components/Account/WebhookStatusPopup";

function useAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { handlePopupCenterOpen, handlePopupCenterComponentRender } =
    usePopup();

  const axiosPrivate = useAxiosPrivate();

  const handleAccount = useCallback(() => {
    dispatch(setAccount());
    getAccount(axiosPrivate)
      .then((response) => {
        dispatch(setAccountSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          setAccountError(
            error?.response?.data?.errors?.[0]
              ? error?.response?.data?.errors?.[0]
              : "Some server error occured, please try again later!"
          )
        );
        notify(
          error?.response?.data?.errors?.[0]?.message
            ? error?.response?.data?.errors?.[0]?.message
            : "Some server error occured, please try again later!",
          "error"
        );
      });
  }, [dispatch, navigate]);

  const handleRefreshAccountKeys = useCallback(
    (otp, email) => {
      dispatch(setAccountKeys());
      accountKeys(axiosPrivate)
        .then((response) => {
          dispatch(setAccountKeysSuccess(response.data));
          handlePopupCenterComponentRender(<ApiKeyPopup />);
          handlePopupCenterOpen(true);
          notify("Keys updated successfully", "success");
        })
        .catch((error) => {
          dispatch(
            setAccountKeysError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, navigate]
  );

  const handleAccountKeysToggle = useCallback(
    (enabled) => {
      dispatch(setAccountKeysToggle());
      accountKeysToggle(axiosPrivate, enabled)
        .then((response) => {
          dispatch(setAccountKeysToggleSuccess(response.data.message));
          notify("Key toggled successfully", "success");
          handleAccount();
        })
        .catch((error) => {
          dispatch(
            setAccountKeysToggleError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          notify(
            error?.response?.data?.errors?.[0]?.message
              ? error?.response?.data?.errors?.[0]?.message
              : "Some server error occured, please try again later!",
            "error"
          );
        });
    },
    [dispatch, navigate]
  );

  const handleAccountWebhook = useCallback(
    (webhookUrl) => {
      dispatch(setAccountWebhook());

      accountWebhook(axiosPrivate, webhookUrl)
        .then((response) => {
          dispatch(setAccountWebhookSuccess(response.data.message));
          notify("Webhook URL set successfully", "success");
          handlePopupCenterComponentRender(<WebhookStatusPopup />);
          handlePopupCenterOpen(true);
        })
        .catch((error) => {
          dispatch(
            setAccountWebhookError(
              error?.response?.data?.errors?.[0]
                ? error?.response?.data?.errors?.[0]
                : "Some server error occured, please try again later!"
            )
          );
          handlePopupCenterComponentRender(<WebhookStatusPopup />);
          handlePopupCenterOpen(true);
        });
    },
    [dispatch, navigate, location]
  );

  return {
    handleAccount,
    handleRefreshAccountKeys,
    handleAccountKeysToggle,
    handleAccountWebhook,
  };
}

export default useAccount;
