import React from "react";
import { useSelector } from "react-redux";
import LayoutComponent from "../../../Components/Onboarding/LayoutComponent";
import OtpComponent from "../../../Components/Onboarding/OtpComponent";
import staticData from "../../../Components/staticData";
import useAuth from "../../../Hooks/useAuth";
import styles from "./VerifyOTPPage.module.css";

const signInPage = staticData.signInUpPage;

const VerifyOTPPage = () => {
  const { handleVerifyOTP: handleValidateSignUpOTP, handleResendOtp } =
    useAuth();
  const { email, mobile } = useSelector((state) => state.auth);
  const authFunction = (otp) => {
    handleValidateSignUpOTP(otp, email);
  };

  const handleResendSignupOtp = () => {
    handleResendOtp(email);
  };

  return (
    <div className={styles.Wrapper}>
      <LayoutComponent
        renderComp={
          <OtpComponent
            title={signInPage.signUp.verifyOtpPage.title}
            buttonTxt={signInPage.signUp.verifyOtpPage.btnText}
            authFunction={authFunction}
            handleResendOtp={handleResendSignupOtp}
            email={email}
            mobile={mobile}
          />
        }
        headerText={signInPage.signUp.subTitle[0]}
        headerLinkText={signInPage.signUp.subTitle[1]}
        headerLink={"/signup"}
      />
    </div>
  );
};

export default VerifyOTPPage;
