import staticData from "../../staticData";
import styles from "./UsageOverviewComponent.module.css";

const content = staticData.UsageOverviewComponent;

const UsageOverviewComponent = () => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.HeadingWrapper}>
        <h1>{content.heading}</h1>
        <span className={styles.SubHeading}>{content.subHeading}</span>
      </div>
      <div className={styles.ProgressBarWrapper}>
        <h2 className={styles.Heading}>64,000</h2>
        {/* Progress bar */}
      </div>
      <div className={styles.CreditsInfoWrapper}>
        <span className={styles.InfoText}>{content.creditsUtilized}</span>
        <span className={styles.InfoText}>{content.totalCredits}</span>
      </div>
      <table className={styles.UsageTable}>
        <tbody>
          <tr>
            <td className={styles.TextAlignLeft}>
              {content.tableContent.transactionProcessed}
            </td>{" "}
            <td className={`${styles.TextAlignRight} ${styles.TableData}`}>
              1234
            </td>
          </tr>
          <tr>
            <td className={styles.TextAlignLeft}>
              {content.tableContent.usersCreated}
            </td>{" "}
            <td className={`${styles.TextAlignRight} ${styles.TableData}`}>
              1234
            </td>
          </tr>
          <tr>
            <td className={styles.TextAlignLeft}>
              {content.tableContent.taxReportsGenerated}
            </td>{" "}
            <td className={`${styles.TextAlignRight} ${styles.TableData}`}>
              453234
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default UsageOverviewComponent;
