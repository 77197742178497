import { useSelector } from "react-redux";
import useAccount from "../../../Hooks/useAccount";

import Button from "../../Button/Button";
import staticData from "../../staticData";
import styles from "./GenerateApiKey.module.css";

const content = staticData.ApiKeyPage.GenerateApiKey;

const GenerateApiKey = () => {
  const accState = useSelector((state) => state.account);
  const loading = accState?.loading;

  const { handleRefreshAccountKeys } = useAccount();

  const handleGenerateApiKey = () => {
    handleRefreshAccountKeys();
  };

  return (
    <div className={styles.Wrapper}>
      <h1> {content.heading} </h1>
      <hr className={styles.Divider} />
      <Button
        text={content.genrateBtnText}
        btnType={"primary"}
        onClick={handleGenerateApiKey}
        isLoading={loading}
        isDisabled={loading}
      />
    </div>
  );
};

export default GenerateApiKey;
