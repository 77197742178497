import Button from "../../Button/Button";
import Input from "../../Input";
import BlueDocsIcon from "../../../Assets/General/BlueDocsIcon.svg";
import staticData from "../../staticData";
import usePopup from "../../../Hooks/usePopup";
import styles from "./ListenEvents.module.css";
import useAccount from "../../../Hooks/useAccount";
import { useState } from "react";
import { useSelector } from "react-redux";

const content = staticData.AccountPage.ListenEvents;

const ListenEvents = () => {
  const [webHookUrl, setWebHookUrl] = useState("");
  const { account: accountData, loading } = useSelector(
    (state) => state.account
  );

  const [showInput, setShowInput] = useState(false);

  const { handlePopupCenterOpen, handlePopupCenterComponentRender } =
    usePopup();

  const { handleAccountWebhook } = useAccount();

  const handleChange = (e) => {
    setWebHookUrl(e.target.value);
  };

  const handleSubmit = () => {
    handleAccountWebhook(webHookUrl);
  };

  const handleAddWebhook = () => {
    setShowInput(true);
  };

  return (
    <div className={styles.Wrapper}>
      <h1 className={styles.Heading}> {content.heading} </h1>
      {accountData?.webhookURLs || showInput ? null : (
        <Button
          className={styles.AddHookBtn}
          text={"Add Webhook"}
          btnType="primary"
          onClick={handleAddWebhook}
        />
      )}
      {showInput ? (
        <div className={styles.InputWrapper}>
          <div className={styles.LabelInputWrapper}>
            <label className={styles.Label} htmlFor="">
              Webhook URL
            </label>
            <Input value={webHookUrl} onChange={handleChange} />
          </div>
          {/* <Button
            className={styles.Btn}
            btnType={"primary"}
            text={content.editBtnText}
          /> */}
          <Button
            className={styles.Btn}
            btnType={"primary"}
            text={"Submit"}
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={loading}
          />
        </div>
      ) : null}
      <div className={styles.NoteWrapper}>
        <img src={BlueDocsIcon} alt="Docs" className={styles.DocsIcon} />
        <span className={styles.Note}>{content.note}</span>
      </div>
    </div>
  );
};

export default ListenEvents;
