import { useEffect, useState, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import Hamburger from "hamburger-react";
import { ReactComponent as ArrowDown } from "../../Assets/General/DownArrow.svg";
// import ProfileUpdatePopUp from "../Onboarding/ProfileUpdatePopUp";
import usePopup from "../../Hooks/usePopup";
import staticData, { images } from "../staticData";
import styles from "./Navbar.module.css";
import Button from "../Button/Button";
import useAuth from "../../Hooks/useAuth";

const content = staticData.SideMenu;

function Navbar() {
  const [isOpen, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef();
  const { pathname } = useLocation();

  const { account: accountData } = useSelector((state) => state.account);

  const { handleUserLogout } = useAuth();
  const { handlePopupCenterComponentRender, handlePopupCenterOpen } =
    usePopup();
  const navbarData = staticData.navbar;

  useEffect(() => {
    const accordian = document.getElementsByClassName(
      styles.HamburgerCategoriesWrapper
    )[0];
    if (isOpen) {
      accordian.style.height = "calc(100vh - 7.5rem)";
      accordian.style.visibility = "visible";
      accordian.style.opacity = "1";
    } else {
      accordian.style.height = "0px";
      accordian.style.visibility = "hidden";
      accordian.style.opacity = "0";
    }

    const dropdownClose = (e) => {
      if (dropdown && ref.current && !ref.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", dropdownClose);
  }, [isOpen, dropdown]);

  const handleLinkClick = () => {
    setOpen(false);
    setDropdown(false);
  };

  const handleSignout = () => {
    handleUserLogout();
  };

  // const handleProfileErrorIconClick = (e) => {
  //   e.stopPropagation();
  //   if (userData) {
  //     handlePopupCenterComponentRender(
  //       <ProfileUpdatePopUp
  //         firstName={userData?.firstName}
  //         lastName={userData?.lastName}
  //         email={userData?.email}
  //         mobile={userData?.mobile}
  //       />
  //     );
  //     handlePopupCenterOpen(true);
  //   }
  // };

  return (
    <div className={styles.Navbar}>
      <div className={styles.Wrapper}>
        <div className={styles.LogoCategoriesWrapper}>
          <Link to="/">
            <img src={images.logo} alt="logo" className={styles.Logo} />
          </Link>
        </div>
        <div
          className={styles.ProfileWrapper}
          onClick={() => {
            setDropdown(!dropdown);
            setOpen(!isOpen);
          }}
        >
          <div className={styles.ProfileContainer}>
            <img
              src={navbarData.profile.icon}
              alt={"profile pic"}
              className={styles.Profile}
            />
            <span className={styles.ProfileName}>
              {accountData?.organization.name}
            </span>
          </div>
          <ArrowDown className={styles.ArrowDown} />
          {/* {!userData?.profileCompleted && (
            <img
              src={navbarData.profile.ProfileErrorIcon}
              alt="Profile Error"
              onClick={handleProfileErrorIconClick}
              className={styles.ProfileErrorIcon}
            />
          )} */}
        </div>
        {dropdown && (
          <div className={styles.Dropdown} ref={ref}>
            {navbarData.profileDropdown.dropdownList.map((item) => (
              <Link
                key={item.label}
                to={`${item.path}`}
                className={styles.DropdownItems}
                onClick={() => setDropdown(false)}
              >
                {item.label}
              </Link>
            ))}
            <hr className={styles.Line} />
            <p className={styles.DropdownItems} onClick={handleSignout}>
              {navbarData.profileDropdown.signout.label}
            </p>
          </div>
        )}

        <div className={styles.Hamburger}>
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            rounded
            size={20}
            color="var(--primary-blue)"
          />
          {/* {!userData?.profileCompleted && (
            <img
              src={navbarData.profile.ProfileErrorIcon}
              alt="Profile Error"
              onClick={handleProfileErrorIconClick}
            />
          )} */}
        </div>
      </div>
      <hr className={styles.BorderBottom} />
      <div
        className={styles.HamburgerCategoriesWrapper}
        style={{
          height: "0",
          visibility: "hidden",
          opacity: "0",
        }}
      >
        <div className={styles.LowerNavWrapper}>
          {/* <NavLink
            className={({ isActive }) =>
              styles.Category + (isActive ? " " + styles.ActiveCategory : "")
            }
            to={navbarData.profile.path}
            onClick={handleLinkClick}
          >
            {navbarData.profileDropdown.dropdownList[0].label}
          </NavLink> */}
          {/* <div className={styles.Category} onClick={handleSignout}>
            {navbarData.profileDropdown.signout.label}
          </div> */}
          <>
            {content.options.map((option) => (
              <NavLink to={option.route}>
                <Button
                  isReverse={true}
                  key={option.btnText}
                  text={option.btnText}
                  src={option.image}
                  className={`${
                    // pathname.includes(option.key) && option.key
                    // ? styles.ActiveMenu
                    styles.MenuBtn
                  }`}
                  imgClassName={styles.MenuIcon}
                  onClick={handleLinkClick}
                />
              </NavLink>
            ))}
          </>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
