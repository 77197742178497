import { createStore, combineReducers } from "redux";
import { accountReducer } from "./account/reducer";
import { authReducer } from "./auth/reducer";
import { popupReducer } from "./popup/reducer";
import { userReducer } from "./user/reducer";

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      account: accountReducer,
      popup: popupReducer,
      user: userReducer,
    })
  );
  return store;
};
