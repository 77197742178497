import {
  GET_ACCOUNT,
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
  ACCOUNT_KEYS,
  ACCOUNT_KEYS_ERROR,
  ACCOUNT_KEYS_SUCCESS,
  ACCOUNT_KEYS_TOGGLE,
  ACCOUNT_KEYS_TOGGLE_ERROR,
  ACCOUNT_KEYS_TOGGLE_SUCCESS,
  ACCOUNT_WEBHOOK,
  ACCOUNT_WEBHOOK_ERROR,
  ACCOUNT_WEBHOOK_SUCCESS,
} from "./types";

export function setAccount() {
  return {
    type: GET_ACCOUNT,
  };
}

export function setAccountSuccess(accountData) {
  return {
    type: GET_ACCOUNT_SUCCESS,
    payload: accountData,
  };
}

export function setAccountError(error) {
  return {
    type: GET_ACCOUNT_ERROR,
    payload: error,
  };
}

export function setAccountKeys() {
  return {
    type: ACCOUNT_KEYS,
  };
}

export function setAccountKeysSuccess(data) {
  return {
    type: ACCOUNT_KEYS_SUCCESS,
    payload: data,
  };
}

export function setAccountKeysError(error) {
  return {
    type: ACCOUNT_KEYS_ERROR,
    payload: error,
  };
}

export function setAccountKeysToggle() {
  return {
    type: ACCOUNT_KEYS_TOGGLE,
  };
}

export function setAccountKeysToggleSuccess(message) {
  return {
    type: ACCOUNT_KEYS_TOGGLE_SUCCESS,
    payload: message,
  };
}

export function setAccountKeysToggleError(error) {
  return {
    type: ACCOUNT_KEYS_TOGGLE_ERROR,
    payload: error,
  };
}

export function setAccountWebhook() {
  return {
    type: ACCOUNT_WEBHOOK,
  };
}

export function setAccountWebhookSuccess(message) {
  return {
    type: ACCOUNT_WEBHOOK_SUCCESS,
    payload: message,
  };
}

export function setAccountWebhookError(error) {
  return {
    type: ACCOUNT_WEBHOOK_ERROR,
    payload: error,
  };
}
